<template>
  <LayoutDefault>
    <Loading :active="loading" />

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <Toast
        :message="toastMessage"
        v-model:active="toastActive"
        :type="toastType"
      />
      <!-- Content Header (Page header) -->
      <div class="content-header mt-2">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
              <router-link :to="{ name: 'News' }" class="custom-back m-0 ttU">
                <i class="fas fa-arrow-circle-left"></i> Atrás
              </router-link>
            </div>
            <!-- /.col -->
          </div>
          <div class="row mb-2">
            <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
              <h1 class="m-0 text-dark ttU">Editar</h1>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->

      <section class="content">
        <div class="container-fluid">
          <form action="" class="form pb-5">
            <div class="inputs">
              <div class="form-group">
                <label for="exampleInputPassword1">Titulo</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="titulo"
                  name="title"
                  v-model="newD.title"
                />
              </div>
              <div class="form-group">
                <label for="category">Categoria</label>
                <select
                  class="form-control"
                  v-model="statusSelected"
                  id="category"
                >
                  <option
                    v-for="(status, index) in statusArray"
                    :value="status.toLowerCase()"
                    :key="index"
                  >
                    {{ status }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="user_role">Rol de Usuario</label>
                <select
                  class="form-control"
                  v-model="rolSelected"
                  id="user_role"
                >
                  <option value="">Seleccione Rol de usuario</option>
                  <option
                    v-for="(rol, index) in roles"
                    :value="rol.value"
                    :key="index"
                  >
                    {{ rol.label }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="user_type">Tipo de Usuario</label>
                <select
                  class="form-control"
                  v-model="topicSelected"
                  id="user_type"
                >
                  <option value="">Seleccione tipo de usuario</option>
                  <option
                    v-for="(topic, index) in topics"
                    :value="topic.id"
                    :key="index"
                  >
                    {{ topic.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="exampleFormControlSelect1" style="display: block">Agregar EDS</label>
                <select
                  class="eds form-control"
                  multiple="multiple"
                  id="exampleFormControlSelect1"
                >
                  <option
                    v-for="(station, index) in stations"
                    :value="station.pbl"
                    :key="index"
                    :selected="
                      newD.eds && newD.eds.length > 0
                        ? newD.eds?.split(',').find((el) => station.pbl == el)
                          ? true
                          : false
                        : false
                    "
                  >
                    {{ station.service_station }} (PBL #{{ station.pbl }})
                  </option>
                </select>
              </div>
              <div class="form-group">
                <textarea
                  class="form-control"
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  placeholder="descripción"
                  v-model="newD.description"
                ></textarea>
              </div>

              <div class="form-group">
                <label for="iframe">Contenido incrustado (IFrame)</label>
                <input
                  id="iframe"
                  type="text"
                  class="form-control"
                  placeholder="https://iframes.com"
                  name="iframe"
                  v-model="newD.iframe_url"
                >
              </div>

              <section>
                <h6 class="mb-3">Descargables</h6>

                <UploadFiles @change="files = $event" :files="newD.news_files" />
              </section>

              <button
                type="button"
                class="button btn btn-default btn-default-invert"
                @click="saveData()"
              >
                Guardar
              </button>
            </div>
            <div class="image">
              <div class="image-container">
                <img
                  :alt="newD.title"
                  :src="
                    newD.image.length > 0
                      ? newD.image
                      : previewImage.length > 0
                      ? previewImage
                      : 'https://via.placeholder.com/400'
                  "
                />
              </div>
              <div class="form-group">
                <button
                  type="button"
                  class="button btn btn-default btn-default-invert"
                  @click="$refs.fileA.click()"
                >
                  Cambiar imagen
                </button>
                <input
                  type="file"
                  ref="fileA"
                  class="form-control-file"
                  id="exampleFormControlFile1"
                  style="display: none"
                  @change="handleImage()"
                />
              </div>
            </div>
          </form>
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from "../layout/LayoutAdmin"
import Loading from "../components/Loading"
import Toast from "../components/Toast"
import UploadFiles from "../components/UploadFiles.vue"

import $ from "jquery"
import config from "@/config/config"
import stationService from "@/services/stationService"
import userService from "@/services/userService"
import newsService from "@/services/newsService"

import { uploadFile } from "@/services/fileService"

export default {
  name: `NewsEdit`,
  data() {
    return {
      newD: {
        id: 0,
        body: "",
        title: "",
        description: "",
        image: "",
        eds: "",
        topic: "",
        iframe_url: "",
        news_files: []
      },
      config: config.getConfig(),
      loading: true,
      previewImage: "",
      toastType: "",
      toastActive: false,
      toastMessage: "",
      statusSelected: "Todos",
      topicSelected: "",
      edsSelected: "",
      rolSelected: "",
      statusArray: ["Todos", "Esso", "Primax"],
      topics: [],
      stations: [],
      roles: [],
      files: [],
    };
  },
  async beforeMount() {
    this.loading = true;
    if (this.$route.params.new_id) {
      await this.getNewFull(this.$route.params.new_id);
    } else {
      this.$router.push("/dashboard/news");
    }
  },
  async mounted() {
    let res = await userService.getUserTopics();

    if (res && res.response) {
      if (res.response?.data) {
        this.topics = res.response.data;
      }
      this.topics = res.response;
    }

    res = await stationService.getAllStations();

    if (res && res.response) {
      if (res.response?.data) {
        this.stations = res.response.data;
      }
      this.stations = res.response;
    }

    res = await userService.getRoles();

    if (res && res.response) {
      if (res.response?.data) {
        this.roles = res.response.data;
      }
      this.roles = res.response;
    }

    this.loading = false;

    setTimeout(() => {
      $(".eds")
        .select2()
        .trigger("change")
        .on("change", () => {
          //getting tags every change
          this.edsSelected = $(".eds").select2("data");
        });
    }, 500);
  },
  methods: {
    async saveData() {
      this.loading = true;
      this.edsSelected = $(".eds").select2("data");

      // FILES

      this.newD.news_files = await Promise.all(this.files.map(async item => {
        if (item.blob) {
          const url = await uploadFile(item.blob)

          return {
            label: item.label,
            url: url
          }
        }

        return {
          label: item.label,
          url: item.url
        }
      }))

      const data = {
        ...this.newD,
        category: this.statusSelected.toLowerCase(),
        eds:
          this.edsSelected.length > 0
            ? this.edsSelected.map((eds) => eds.id).join()
            : "",
        ...(this.topicSelected !== ""
          ? {
              topic: String(this.topicSelected),
            }
          : { topic: "" }),
        ...(this.rolSelected !== ""
          ? {
              role: String(this.rolSelected),
            }
          : { role: "" }),
      };
      this.previewImage.length > 0
        ? (data.image = this.previewImage)
        : delete data.image;

      const res = await newsService.updateNew(data.id, data);

      if (res.error || (res.code && res.code !== 200)) {
        this.toastType = "error";
        this.toastMessage = "Fallo al guardar";
        this.toastActive = true;
        this.loading = false;
        return;
      }
      
      this.toastType = "success";
      this.toastMessage = "Guardado correctamente";
      this.toastActive = true;
      this.loading = false;
    },
    async getNewFull(id) {
      const res = await newsService.getNew(id);
      if (res.error || (res.code && res.code !== 200)) {
        this.$router.push("/dashboard/news");
        return;
      }
      if (res && res.response) {
        this.newD = { ...res.response };
        this.statusSelected = this.newD.category.toLowerCase();
        this.edsSelected = this.newD.eds?.toLowerCase();
        this.topicSelected = this.newD.topic
          ? this.newD.topic?.toLowerCase()
          : "";
        this.rolSelected = this.newD.role || "";
      }
    }, // 1
    async handleImage() {
      this.file = this.$refs.fileA.files[0];
      const filebase64 = await this.toBase64(this.file);
      this.previewImage = filebase64;
    },
    toBase64: async (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      }),
  },
  components: {
    LayoutDefault,
    Loading,
    Toast,
    UploadFiles,
  },
};
</script>
<style lang="scss">
.btn-edit {
  position: absolute;
  right: 0.5em;
  bottom: 0.5em;
}
.note-fontname {
  display: none;
}
.form {
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-around;
  .inputs {
    width: 40%;
    .button {
      margin-top: 1em;
    }
  }
  .image {
    width: 40%;
    &-container {
      display: flex;
      align-items: center;
      max-height: 360px;
      overflow: hidden;
    }
    .button {
      margin-top: 1em;
    }
    img {
      width: 485px !important;
    }
  }
  &-group {
    width: 100%;
    position: relative;
  }
  &-control {
    padding: 1em;
    border-radius: 8px;
    resize: none;
    height: inherit;
    &:focus {
      border-radius: 8px;
    }
  }
}
</style>
