<template>
  <LayoutDefault>
    <Loading :active="loading" />
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <Toast
        :message="toastMessage"
        v-model:active="toastActive"
        :type="toastType"
      />
      <!-- Content Header (Page header) -->
      <div class="content-header mt-2">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
              <router-link :to="{ name: 'training' }" class="custom-back m-0 ttU">
                <i class="fas fa-arrow-circle-left"></i> Atrás
              </router-link>
            </div>
            <!-- /.col -->
          </div>
          <div class="row mb-2">
            <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
              <h1 class="m-0 text-dark ttU">Editar</h1>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->

      <section class="content">
        <div class="container-fluid">
          <form action="" class="form">
            <div class="inputs">
              <div class="form-group">
                <label for="exampleInputPassword1">Titulo</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="titulo"
                  name="title"
                  v-model="newD.title"
                />
              </div>
              <div class="form-group">
                <label for="exampleFormControlSelect1">Tipo</label>
                <select
                  class="form-control"
                  v-model="trainingTypeSelected"
                  id="exampleFormControlSelect1"
                >
                  <option value="">Seleccione tipo de entrenamiento</option>
                  <option
                    v-for="(training, index) in trainingTypes"
                    :value="training.value"
                    :key="index"
                  >
                    {{ training.label }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">URL Imagen</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="url imagen"
                  name="image"
                  v-model="newD.img_url"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">URL Streaming</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="url streaming"
                  name="link"
                  v-model="newD.url_streaming"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Descripción</label>
                <textarea
                  class="form-control"
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  placeholder="descripción"
                  v-model="newD.description"
                ></textarea>
              </div>
              <button
                type="button"
                class="button btn btn-default btn-default-invert"
                @click="saveData()"
              >
                Guardar
              </button>
            </div>
            <div class="image">
              <div class="image-container">
                <!-- <img
                  :src="
                    newD?.img_url.length > 0
                      ? `${config.apiUrl.replace(/\/api$/g, '/' + newD.img_url)}`
                      : previewImage.length > 0
                      ? previewImage
                      : 'https://via.placeholder.com/630x360'
                  "
                  alt=""
                /> -->
                <img :src="newD?.img_url" alt="" />
              </div>
              <div class="form-group">
                <button
                  type="button"
                  class="button btn btn-default btn-default-invert"
                  @click="$refs.fileA.click()"
                >
                  Cambiar imagen
                </button>
                <input
                  type="file"
                  ref="fileA"
                  class="form-control-file"
                  id="exampleFormControlFile1"
                  style="display: none"
                  @change="handleImage()"
                />
              </div>
            </div>
          </form>
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from "../layout/LayoutAdmin";
import Loading from "../components/Loading";
// import newsService from "@/services/newsService";
import config from "@/config/config";
import Toast from "../components/Toast";
// import $ from "jquery";
// import stationService from "@/services/stationService";
// import userService from "@/services/userService";
import trainingService from '@/services/trainingService';
export default {
  name: `trainingEdit`,
  data() {
    return {
      newD: {
        created_at: "",
        description: "",
        id: this.$route.params.new_id,
        img_url: "",
        title: "",
        type: "",
        updated_at: "",
        url_streaming: "",
      },
      config: config.getConfig(),
      loading: true,
      trainingTypes: [],
      trainingTypeSelected: '',
      previewImage: "",
      toastType: "",
      toastActive: false,
      toastMessage: "",
      form: undefined,
    };
  },
  async beforeMount() {
    this.loading = true;
    if (this.$route.params.new_id) {
      await this.getMediaContent(this.$route.params.new_id);
    } else {
      this.$router.push("/dashboard/training");
    }
  },
  async mounted() {
    this.loading = false;
    const responseTrainingTypes = await trainingService.getTrainingTypes();
    if (responseTrainingTypes.length) { 
      this.trainingTypes = responseTrainingTypes;
    }
  },
  methods: {
    async saveData() {
      this.loading = true;
      const data = { ...this.newD, type: String(this.trainingTypeSelected) };
      const res = await trainingService.updateTrainingItem(
        this.$route.params.new_id,
        data
      );
      if (res.error || (res.code && res.code !== 200)) {
        this.toastType = "error";
        this.toastMessage = "Fallo al guardar";
        this.toastActive = true;
        this.loading = false;
        return;
      }
      this.toastType = "success";
      this.toastMessage = "Guardado correctamente";
      this.toastActive = true;
      this.loading = false;
    },
    async getMediaContent(id) {
      const res = await trainingService.getTrainingItem(id);
      console.log('res', { ...res });
      if (res == undefined || res == null) {
        this.$router.push("/dashboard/training");
      }
      this.newD = { ...res };
      this.trainingTypeSelected = res.type;
    },
    async handleImage() {
      this.file = this.$refs.fileA.files[0];
      const filebase64 = await this.toBase64(this.file);
      this.previewImage = filebase64;
    },
    toBase64: async (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      }),
  },
  components: {
    LayoutDefault,
    Loading,
    Toast,
  },
};
</script>

<style lang="scss">
.btn-edit {
  position: absolute;
  right: 0.5em;
  bottom: 0.5em;
}
.note-fontname {
  display: none;
}
.form {
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-around;
  .inputs {
    width: 40%;
    .button {
      margin-top: 1em;
    }
  }
  .image {
    width: 40%;
    &-container {
      display: flex;
      align-items: center;
      max-height: 360px;
      overflow: hidden;
    }
    .button {
      margin-top: 1em;
    }
    img {
      width: 630px;
    }
  }
  &-group {
    width: 100%;
    position: relative;
  }
  &-control {
    padding: 1em;
    border-radius: 8px;
    resize: none;
    height: inherit;
    &:focus {
      border-radius: 8px;
    }
  }
}
</style>
