
import { defineComponent, onBeforeMount, reactive, toRaw, toRefs, computed } from "vue"

import LayoutDefault from "@/layout/LayoutAdmin.vue"
import Loading from "@/components/Loading.vue"
import Toast from "@/components/Toast.vue"

import bannersService from "@/services/bannersService"
import { useRoute } from "vue-router"

export default defineComponent({
    components: {
        LayoutDefault,
        Loading,
        Toast,
    },

    setup() {
        const state = reactive({
            sections: [],
            status: "loading",
            message: ""
        })

        const banner = reactive({
            title: "",
            img: "",
            description: "",
            price: "",
            section: "",
            link: "",
        })

        const { params } = useRoute()

        const id = computed(() => params.id?.toString())

        async function fetchData() {
            const response = await bannersService.getBannersItem(id.value)

            if (response.error) {
                console.warn(response)
                // LOG
            }

            else {
                banner.title = response.title
                banner.img = response.img
                banner.description = response.description
                banner.price = response.price
                banner.section = response.section
                banner.link = response.link
            }
        }

        async function fetchSections() {
            const response = await bannersService.getBannersSections()

            if (response.error) {
                console.error(response)
                
                state.status = "error"
                state.message = "Oops... algo ha fallado"
            }
            
            else {
                state.sections = response
            }
        }

        onBeforeMount(async function () {
            state.status = "loading"

            try {
                await fetchSections()
                await fetchData()

                state.status = ""
            }

            catch (err) {
                console.error(err)
                // LOG
            }
        })

        async function save() {
            state.status = "loading"
            
            try {
                const response = await bannersService.updateBannersItem(id.value, toRaw(banner))

                if (response.error) {
                    state.status = "error"
                    state.message = "Algo ha fallado, intenta nuevamente"
                }

                else {
                    state.status = "success"
                    state.message = "Contenido editado correctamente"
                }
            }

            catch (err) {
                console.error(err)
                // LOG
            }
        }

        return {
            state,
            ...toRefs(banner),

            save
        }
    }
})
